import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import PageOne from "./PageOne";
import PageTwo from "./PageTwo";
import "./App.css";

function App() {
  const [inputText, setInputText] = useState("");

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route
            path="/rating/ivandi/naaku"
            element={
              <PageOne inputText={inputText} setInputText={setInputText} />
            }
          />
          <Route
            path="/cutiee"
            element={<PageTwo inputText={inputText} />}
          />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
