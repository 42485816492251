import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./PageTwo.css"; // Ensure you import the CSS
import smilingCat from "./So_happy_smiling_cat.jpg"; // Adjust the path as necessary

const PageTwo = ({ inputText }) => {
  const [rating, setRating] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = () => {
    if (rating) {
      setSubmitted(true);
    } else {
      alert("Please select a rating before submitting.");
    }
  };

  return (
    <div className="page">
      <h1>Rate from 1 to 5</h1>
      <div className="rating-container">
        {[...Array(5)].map((_, index) => (
          <label key={index} className="radio-label">
            <input
              type="radio"
              value={index + 1}
              checked={rating === index + 1}
              onChange={() => setRating(index + 1)}
              className="radio-input"
            />
            <span className="custom-radio"></span>
            {index + 1}
          </label>
        ))}
      </div>
      <button onClick={handleSubmit}>Submit Rating</button>

      {submitted && (
        <div style={{ marginTop: "30px", textAlign: "center" }}>
          <img src={smilingCat} alt="Smiling Cat" />
          <p>{inputText}</p>
          <p style={{ color: "red", fontSize: "20px" }}>
            .- .... .... .... .... -.-.-- -.-.-- -.-.-- / -.- .- -. .. .--. ..
            ... - ..- -. -.. .. / -.- .- -. .. .--. .. ... - ..- -. -.. .. /
            -.-.-- -.-.-- -.-.-- / -- . . .-. ..- / ... .. --. --. ..- / .--. .-
            -.. . -.. .... .. / -.- .- -. .. .--. .. ... - ..- -. -.. .. .-.-.-
            / -... .... .- .-.. . / ... .. --. --. ..- / .--. .- -.. ..- - ..-
            -. -. .- ...- / .--. .- .. -. .- / .--. .. .-.. .-.. .. / .--. ..
            .-.. .-.. .- / .-.. .- .- --. .- .-.-.- / -.-. .... .- .- .-.. .- /
            .- -. -.. .... .- -. --. .- / ..- -. -. .- ...- . -.-- -.-- -.--
            .-.-.- / .. -.. .... . / ... .. --. --. ..- / .--. . .-.. .-.. .. /
            -.. .- .- -.- .- / -- .- .. -. - .- .. -. / -.-. .... . -.-- .- -.
            -.. .. .-.-.- / .--. .- -. .. / -.-. .... ..- ... -.- --- -. -.. ..
            / .. -. -.- .- / .--. --- -.-- -.-- .. .-.-.- / .. / .-.. --- ...- .
            / ..- / ---... -.--.- .-.-.- / ..- .-. .-.. / -.-. .... ..- ... .-
            ...- .- / .. -. - .... .- -.- ..-
          </p>
        </div>
      )}
    </div>
  );
};

export default PageTwo;
