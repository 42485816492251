import React from "react";
import { Link } from "react-router-dom";

const PageOne = ({ inputText, setInputText }) => {
  return (
    <div className="page">
      <h1>Please follow below instructions </h1>
      <h1>
        . -- / -.-. .... ..- ... - ..- -. -. .- ...- / .--. --- - - .. .-.-.- /
        ... .... --- -.-. -.- / .- -.-- .. -. -.. .. / -.-. .... .- .- .-.. ..-
        .-.-.- / -. . -..- - / .--. .- --. . / .-.. --- / .-. .- - .. -. --. /
        .. ...- ...- ..- / ---... -.--.-
      </h1>

      <Link to="/cutiee">
        <button>Rating Ivvandi Naaku</button>
      </Link>
    </div>
  );
};

export default PageOne;
